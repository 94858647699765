.kasbon-filter-history {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 8px;
    background: #F2E7DA;
    border-radius: 8px;
    border-width: 0px;
    font-family: "Poppins";
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 12px;
    color: #343434;
}